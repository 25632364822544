@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Extrabold.ttf') format('truetype')
    font-weight: 800
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-UltraLightItalic.ttf') format('truetype')
    font-weight: 200
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-BoldItalic.ttf') format('truetype')
    font-weight: bold
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Semibold.ttf') format('truetype')
    font-weight: 600
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-BlackItalic.ttf') format('truetype')
    font-weight: 900
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-ThinItalic.ttf') format('truetype')
    font-weight: 100
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-ExtraboldItalic.ttf') format('truetype')
    font-weight: 800
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Black.ttf') format('truetype')
    font-weight: 900
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-HeavyItalic.ttf') format('truetype')
    font-weight: 900
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-SemiboldItalic.ttf') format('truetype')
    font-weight: 600
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Bold.ttf') format('truetype')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-RegularItalic.ttf') format('truetype')
    font-weight: normal
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Light.ttf') format('truetype')
    font-weight: 300
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-LightItalic.ttf') format('truetype')
    font-weight: 300
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Heavy.ttf') format('truetype')
    font-weight: 900
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-MediumItalic.ttf') format('truetype')
    font-weight: 500
    font-style: italic

@font-face
    font-family: 'Gilroy'
    src: url('../fonts/Gilroy-Thin.ttf') format('truetype')
    font-weight: 100
    font-style: normal
