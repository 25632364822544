@import 'bootstrap'
@import '~bootstrap/scss/bootstrap'

@import 'variables'
@import 'fonts'

@import 'layouts/layouts'

*
    font-family: 'Gilroy', sans-serif

html
    height: 100%
    font-family: 'Gilroy', sans-serif
    font-size: 14px

table
    width: 100%

    thead
        background-color: transparent
        border-bottom: 1px solid #E6EAF0

        th
            color: rgba(0, 0, 0, 0.5)
            font-size: 16px
            font-weight: 700

    //tr:nth-child(even)
    //    background-color: #F8F8F9

    th,
    td
        padding: 10px 20px

    & > :not(:first-child)
        border-top: 0

.tabs
    display: flex
    margin-bottom: 30px
    border-bottom: 1px solid #0091FF

    &__tab
        padding: 14px 0
        margin-right: 20px
        color: #666666
        font-size: 18px
        font-weight: 300
        text-decoration: none
        cursor: pointer

        &.active, &_active
            color: #0091FF
            font-weight: 700
            border-bottom: 5px solid #0091FF

        &:hover
            color: #0091FF


.pagination
    display: flex
    justify-content: center
    margin: 0 auto

    .page-link
        padding: 6px 12px
        background-color: #EDF6FE
        font-weight: 700
        border: 0
        border-radius: 3px

        &:hover
            color: #fff
            background-color: #0091FF

    .page-item
        margin-right: 10px
        border: 0

        &.active
            .page-link
                color: #fff
                background-color: #0091FF

.ql-editor
    min-height: 250px

.card
    border-radius: 5px
    //border-color: #0091FF

    &-header
        //background-color: #0091FF
        //color: #fff
        border: 0
        border-top-right-radius: 5px
        border-top-left-radius: 5px

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon,
.apexcharts-zoom-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon
    display: none !important

.demo
    padding: 10px
    background-color: #FF936B
    color: #fff
    text-align: center

    &__button
        margin-left: 10px
        padding: 5px 10px
        background-color: #0091FF
        color: #fff
        text-decoration: none
        border-radius: 20px

        &:hover
            color: #fff
            cursor: pointer
