#auth
    position: relative
    display: flex
    align-items: center
    min-height: 100vh
    background-image: url('../../images/login-bg.png')
    background-repeat: no-repeat
    background-size: cover

    #app
        display: block

    a
        color: #007bff
        font-weight: 600
        text-decoration: none
        transition: all 300ms linear

        &:hover
            color: #000

    .logo
        margin-top: 50px
        margin-bottom: 150px
