#main
    position: relative
    min-height: 100vh
    background-color: rgb(239, 243, 246)

    #app
        display: grid
        grid-template-columns: 240px 1fr

    .aside-menu
        min-height: 100vh
        padding: 20px 0
        background-color: #202C82

        .logo
            margin: 0 20px

        &__info
            margin: 40px 0 20px 0
            padding: 10px 20px
            background-color: #0091FE
            color: #fff
            font-size: 16px

        &__account-name
            display: block
